<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">
        {{
          getProcedureTypes[$route.params.procedureType]
            ? getProcedureTypes[$route.params.procedureType].name
            : ""
        }}
      </div>
      <div class="right" style="margin-top: 1.2rem">
        <div v-if="showPanel" @click="closePanel()" class="goBack">
          <img src="../assets/media/vectors/arrow_back.svg" alt="" />
        </div>
      </div>
    </div>
    <template v-if="PERMISIONS.list">
      <Table
        :key="tableReload"
        :reloadCount="tableReload"
        :Data="tableData"
        :fixedFilter="
          `eq=procedureTypeId,${currentProcedureId}&paap.approveVersion=3&acquisitionInclude=1&verbalProcessInclude=1&announcementInclude=1`
        "
        @Btn7="viewReferences"
        @Btn8="viewAcquisitions"
        @Btn9="viewVerbalProcess"
        @Btn10="viewAnnouncement"
        @previousPage="setPage"
        @nextPage="setPage"
        @fullReload="reloadPage"
        @readFilteredData="filteredDataManager"
        @filterQuery="tableFilterManager"
      >
      </Table>
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/assignmentsPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { PAAP, GET_PAAP_CENTRALIZATION_PAGINATED } from "../api.js";
import { mapMutations, mapGetters } from "vuex";
import Table from "@/components/Table";

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  components: {
    Table,
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  data() {
    return {
      dataLoading: false,
      tableData: {
        ready: 1,
        options: {
          searchModule: "GET_PAAP_CENTRALIZATION_PAGINATED",
          partialDisableFixedCols: window.innerWidth < 500 ? [2, 1] : false,
          refresh: true,
          specialActions: { 1: "showSubTableSlot" },
        },
        head: [
          {
            title: "NR.",
            minimize: 2,
            sort: true,
            fixed: {
              left: true,
            },
          },
          {
            title: "Denumire",
            sort: true,
            queryKey: "name",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "CPV - Denumire",
            queryKey: "cpvCode",
            bottomHead: {
              quikFiltre: "string",
            },
          },

          {
            title:
              "Procedura stabilită/instrumente specifice pentru derularea procesului de achiziție",
            queryKey: "procedureType.name",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title: "Rezultatul procedurii",
            queryKey: "procedureResult",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title: "Valoare estimată(LEI fără TVA)",
            queryKey: "totalValueWithoutTva",
            bottomHead: {
              quikFiltre: "number",
            },
          },
          {
            title: "Sursa de finanțare",
            queryKey: "financingSource.sourceName",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title: "Data (luna) estimată pentru inițierea proceduri",
            queryKey: "estimateInitProcedureDate",
            bottomHead: {
              quikFiltre: Date,
            },
          },
          {
            title:
              "Data (luna) estimată pentru atribuirea contractului de achiziție publică/semnarea acordului-cadru",
            queryKey: "estimateDateForSignContract",
            bottomHead: {
              quikFiltre: Date,
            },
          },
          {
            title: "Modalitatea de derulare a procedurii de atribuire",
            queryKey: "modalityToProcessProcedure",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title: "Persoana responsabilă cu aplicarea procedurii de atribuire",
            queryKey: "responsablePersonRole",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title:
              "Data introducerii procedurii în Programul anual al achizițiilor publice",
            queryKey: "dateForLastProcedureAdded",
            bottomHead: {
              quikFiltre: Date,
            },
          },
          {
            spacer: true,
            minimize: 1,
            fixed: {
              right: true,
            },
            bottomHead: {
              quikFiltre: "clear",
            },
          },
        ],
        body: [],
      },
      currentPage: this.getPaginatedParams(),
      totalItemsLength: 0,
      tableFilter: {
        query: "",
        baseQuery: "",
      },
      totalRecords: 0,
      tableReload: 0,
      financingSource: [],
    };
  },
  methods: {
    ...mapMutations({
      addWindow: "addWindow",
    }),
    viewReferences(item) {
      this.$router.push(
        `${this.$route.path}/${this.tableData.body[item]._Data.id}`
      );
    },
    viewAcquisitions(item) {
      this.$router.push(
        `${this.$route.path}/${this.tableData.body[item]._Data.id}/achizitii`
      );
    },

    viewVerbalProcess(item) {
      this.$router.push(
        `${this.$route.path}/${this.tableData.body[item]._Data.id}/proces-verbal`
      );
    },

    viewAnnouncement(item) {
      this.$router.push(
        `${this.$route.path}/${this.tableData.body[item]._Data.id}/anunt`
      );
    },
    reloadPage(changePage) {
      this.tableData.body.splice(0);
      this.tableReload = 0;
      this.totalItemsLength = 0;
      this.totalRecords = 0;
      (this.currentPage = this.getPaginatedParams()),
        (this.dataLoading = false);
      this.tableFilter.query = "";
      this.managePages(changePage);
    },
    setPage(from, to) {
      if ((!parseInt(from) && from !== 0) || (!parseInt(to) && to !== 0)) {
        return;
      }

      this.currentPage.from = from;
      this.currentPage.to = to;

      this.managePages();
    },
    async getPaapCentralizationData(showPerPage, page) {
      let prepare = [];

      this.tableFilter.baseQuery = `eq=procedureTypeId,${this.currentProcedureId}&paap.approveVersion=3&acquisitionInclude=1&verbalProcessInclude=1&announcementInclude=1`;

      let response = await GET_PAAP_CENTRALIZATION_PAGINATED(
        page,
        showPerPage,
        this.tableFilter.baseQuery.concat(this.tableFilter.query) || false
      );
      if (Number.isInteger(response?.data?.recordsQuantity)) {
        this.totalItemsLength = response.data.recordsQuantity;
        this.tableData.options.totalItems = response.data.recordsQuantity;
      }

      prepare = this.prepareData(response.data.result);
      return prepare;
    },
    async managePages(changePage) {
      if (this.dataLoading) {
        return;
      }
      this.dataLoading = true;

      const { from, to } = this.currentPage;
      const show = to - from;
      const currentPage = from / (to - from) + 1;

      let prepare = [];

      if (to >= this.totalItemsLength && !changePage) return;

      const getPage = await this.getPaapCentralizationData(show, currentPage);

      getPage.forEach((e, i) => {
        e.row.splice(0, 0, {
          val: from + (i + 1),
          border: "right",
        });

        prepare.push(
          Object.assign([...e.row], {
            _Data: e.data,
          })
        );
      });

      this.tableData.body = prepare.length ? prepare : [false];
      this.tableReload++;

      this.dataLoading = false;
    },
    filteredDataManager(data, callback) {
      if (Number.isInteger(data?.data?.recordsQuantity)) {
        this.totalItemsLength = data.data.recordsQuantity;
      }
      if (Array.isArray(data?.data?.result)) {
        callback([
          this.prepareData(data.data.result, {
            minimize: true,
          }),
          data.data.recordsQuantity,
        ]);

        this.tableData.options.totalItems = parseInt(data.data.recordsQuantity);
        this.currentPage = {
          from: 0,
          to: localStorage.getItem("tableShowPerPage")
            ? parseInt(localStorage.getItem("tableShowPerPage"))
            : 15,
        };
        this.dataLoading = false;
      } else {
        callback([false]);
      }
    },
    tableFilterManager(newQuery) {
      if (this.isString(newQuery)) {
        this.tableFilter.query = newQuery;
      }
    },
    reload() {
      this.tableReload++;
    },

    prepareData(rows, o = {}) {
      const preparations = [];

      let i = 1;
      for (const row of rows) {
        let estimateInitProcedureDate = "-";

        if (row.estimateInitProcedureDate) {
          estimateInitProcedureDate = new Date(
            row.estimateInitProcedureDate
          ).toLocaleString("ro-ro", { month: "long", year: "numeric" });
        }

        let estimateDateForSignContract = "-";

        if (row.estimateDateForSignContract) {
          estimateDateForSignContract = new Date(
            row.estimateDateForSignContract
          ).toLocaleString("ro-ro", { month: "long", year: "numeric" });
        }

        let dateForLastProcedureAdded = "-";

        if (row.dateForLastProcedureAdded) {
          dateForLastProcedureAdded = new Date(
            row.dateForLastProcedureAdded
          ).toLocaleDateString("en-US");
        }

        const preparation = [
          row.name || "-",
          row.cpvCode + " - " + row.cpvCodeName || "-",
          row.procedureType?.name || "-",
          row.procedureResult || "-",
          this.toPriceFormat(row.totalValueWithoutTva),
          row.financingSource?.sourceName || "-",
          estimateInitProcedureDate,
          estimateDateForSignContract,
          row.modalityToProcessProcedure || "-",
          this.readUserObject(row.responsablePersonRole?.user) +
            (row.responsablePersonRole?.department?.name
              ? ` — ${row.responsablePersonRole?.department?.name}`
              : "") +
            (row?.responsablePersonRole?.role
              ? ` — ${this.changeRoleView(row.responsablePersonRole?.role)}`
              : "") || "-",
          dateForLastProcedureAdded,
        ];

        const prepareBtn = [[7, "Referate"]];
        
        if (row.acquisitions && row.acquisitions.length) {
          prepareBtn.push([
            8,
            "Achiziții",
            ["fas fa-tag", "font-size:2.2rem; color:green;"],
          ]);
        } 
        /* else {
          prepareBtn.push([
            8,
            "Achiziții",
            ["fas fa-tag", "font-size:2.2rem; color:red;"],
          ]);
        } */

        if (row.verbalProcess && row.verbalProcess.length) {
          prepareBtn.push([
            9,
            "Procese verbale",
            ["fas fa-comments", "font-size:2.2rem; color:green; "],
          ]);
        } /* else {
          prepareBtn.push([
            9,
            "Procese verbale",
            ["fas fa-comments", "font-size:2.2rem; color:red;"],
          ]);
        } */


        if (row.announcement && row.announcement.length) {
          prepareBtn.push([
            10,
            "Anunțuri",
            ["fas fa-bullhorn", "font-size:2.2rem; color:green; "],
          ]);
        }/*  else {
          prepareBtn.push([
            10,
            "Anunțuri",
            ["fas fa-bullhorn", "font-size:2.2rem; color:red;"],
          ]);
        } */

        /*     prepareBtn.push(7);
        if (
          this.PERMISIONS.edit &&
          !this.isHistory &&
          ["draft", "rejected"].includes(row.paap.paapStatus)
        ) {
          prepareBtn.push(2);
        } */

        preparation.push([prepareBtn]);

        if (o.minimize) {
          preparation.splice(0, 0, {
            val: i,
            border: "right",
          });
          preparations.push(preparation);
        } else {
          if (o.numerotate) {
            preparation.splice(0, 0, {
              val: i,
              border: "right",
            });
          }

          const id = row.id ? row.id : "-";

          const returnData = { id, row: preparation, data: row, _Data: row };

          preparations.push(returnData);
        }
        i++;
      }

      if (o.minimize) {
        preparations.forEach((e, i) => (e._ID = i));
      } else if (o.minimize === false || o.numerotate) {
        preparations.forEach((e, i) => {
          e.NR = i;
          e.index = i;
        });
      }

      return preparations;
    },
    closePanel() {
      this.panelData = false;

      this.safeBackRoute();
    },
  },
  computed: {
    ...mapGetters(["getPaapCentralizationTableData", "getProcedureTypes"]),
    showPanel() {
      return !!this.panelData;
    },
    allowTableSync() {
      return true;
    },

    isHistory() {
      return this.$route.path.includes("history");
    },
    currentProcedureId() {
      if (this.getProcedureTypes[this.$route.params.procedureType])
        return this.getProcedureTypes[this.$route.params.procedureType].id;
      return " ";
    },
  },
  watch: {
    $route(val) {
      this.reloadPage(true);
    },
    getProcedureTypes(val) {
      this.reloadPage(true);
    },
  },
  mounted() {
    if (!this.PERMISIONS.list) {
      return;
    }

    this.reloadPage(true);
  },
};
</script>
